import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashborad';
import PrivateRoute from './utils/PrivateRoute';
import Home from './components/Home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmbeddedWebsite from './components/EmbadedWebsite';

const App = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [isAuthenticated, setAuthenticated] = useState();
  useEffect(() => {
    // Update authToken from local storage if available
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
  }, []);
  const handleLogin = (token) => {
    setAuthToken(token);
  };


  return (
    <Router>
      <Routes>

        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/home" element={<EmbeddedWebsite />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login setAuthToken={handleLogin} />} />
        <Route path="/dashboard" element={
          <PrivateRoute authToken={authToken}>
            <Dashboard />
          </PrivateRoute>
        }
        />
        {/* <Route
          path="/create-admin"
          element={
            authToken ? <CreateAdmin /> : <Login />
          }
        /> */}
        {/* <Route path="/add-company" element={authToken && <AddCompany />} /> */}

        

      </Routes>
      <ToastContainer />
    </Router>
  );
};
export default App;
