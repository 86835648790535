// import React from 'react';

// const EmbeddedWebsite = () => {
//   return (
//     <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
//       <iframe
//         src={window.location.origin + "/WebSite/index.html"}  // Path to your existing project (HTML file in public folder)
//         title="Embedded Website"
//         style={{
//           width: '100%',
//           height: '100%',
//           border: 'none',
//           overflow: 'hidden',
//         }}
//       />
//     </div>
//   );
// };

// export default EmbeddedWebsite;
import React from "react";

const EmbeddedWebsite = () => {
  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <iframe
        src="https://quatation-v.netlify.app/" // Local server URL
        title="Embedded Website"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          overflow: "hidden",
        }}
      />
    </div>
  );
};

export default EmbeddedWebsite;
