// import React, { useState, useEffect } from 'react';
// import ProductDetails from '../components/ProductDetails';

// const AllProducts = () => {
//   const [products, setProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedProduct, setSelectedProduct] = useState(null);

//   useEffect(() => {
//     // Fetch products from API
//     const fetchProducts = async () => {
//       const token = localStorage.getItem('token');
//       try {

//         const response = await fetch('https://emkayindia.in/api/v1/product/all', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         const data = await response.json();
//         console.log(data);
//         setProducts(data);
//         setFilteredProducts(data);
//       } catch (error) {
//         console.error('Error fetching products:' + error);
//       }
//     };
//     fetchProducts();
//   }, []);

//   const handleSearch = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);

//     const filtered = products.filter((product) =>
//       product.name.toLowerCase().includes(term.toLowerCase())
//     );
//     setFilteredProducts(filtered);
//   };

//   const handleProductClick = (product) => {
//     setSelectedProduct(product);
//   };

//   return (
//     <div className="p-4 w-[70%]">
//       {!selectedProduct && <div>
//         <h1 className="text-2xl mt-8 font-bold mb-4">Product List</h1>
//         <input
//           type="text"
//           placeholder="Search products..."
//           value={searchTerm}
//           onChange={handleSearch}
//           className="border p-2 mb-4 w-full"
//         />
//         <table className="min-w-full border-collapse border border-gray-300">
//           <thead>
//             <tr className="bg-gray-100">
//               <th className="border border-gray-300 p-2">Product ID</th>
//               <th className="border border-gray-300 p-2">Name</th>
//               <th className="border border-gray-300 p-2">Price</th>
//               <th className="border border-gray-300 p-2">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredProducts.map((product) => (
//               <tr key={product.id} className="cursor-pointer">
//                 <td className="border border-gray-300 p-2">{product.id}</td>
//                 <td className="border border-gray-300 p-2">{product.name}</td>
//                 <td className="border border-gray-300 p-2">{product.price}</td>
//                 <td className="border border-gray-300 p-2">
//                   <button
//                     onClick={() => handleProductClick(product)}
//                     className="text-blue-500 hover:underline"
//                   >
//                     View Details
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>}

//       {selectedProduct && (
//         <ProductDetails product={selectedProduct} />
//       )}
//     </div>
//   );
// };

// export default AllProducts;

import React, { useState, useEffect } from 'react';
import ProductDetails from '../components/ProductDetails';
import EditProduct from './EditProduct';
import axios from 'axios';
import AddProduct from './Pages/AddProduct';

const AllProducts = ({selectedCompany,toggleComponent}) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [Loading,setLoading] = useState(true);


  useEffect(() => {
    // Fetch products from API
    
    const fetchProducts = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://emkayindia.in/api/v1/product/all?companyId=${selectedCompany.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        // console.log(data);
        setLoading(false);
        setProducts(data);
        setFilteredProducts(data);
      } catch (error) {
        console.error('Error fetching products: ' + error);
      }
    };
    fetchCategories();
    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleBackToProducts = () => {
    setSelectedProduct(null);
  };
  const fetchCategories = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get('https://emkayindia.in/api/v1/category/all', {
            headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.data);
    } catch (error) {
        console.error('Error fetching categories', error);
    }
};
 

  return (
    filteredProducts.length>0? 
    <div className={`p-4 w-[100%] bg-[#f9f9f9] mx-auto font-sans text-[#848484] ${selectedProduct?"overflow-y-hidden h-[93vh]":"overflow-y-scroll"} h-[100%]`}>
      {!selectedProduct ? (
        <div className='w-[95%]' >
          <h1 className="text-3xl mt-8 font-bold mb-4 font-sans">Product List</h1>
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearch}
            className="border p-2 mb-4 w-[40%]"
          />
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">Product ID</th>
                <th className="border border-gray-300 p-2">Name</th>
                {/* <th className="border border-gray-300 p-2">Price</th> */}
                <th className="border border-gray-300 p-2">Category</th>
                <th className="border border-gray-300 p-2">Sub Catagory</th>
                <th className="border border-gray-300 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => (
                <tr key={product.id} className="cursor-pointer">
                  <td className="border border-gray-300 p-2">{product.id}</td>
                  <td className="border border-gray-300 p-2">{product.name}</td>
                  {/* <td className="border border-gray-300 p-2 text-right">{product.price} Rs</td> */}
                  <td className="border border-gray-300 p-2 text-center">{product.category.name}</td>
                  <td className="border border-gray-300 p-2 text-center">{product.subCategory.name}</td>
                  <td className="border border-gray-300 p-2 text-center">
                    <button
                      onClick={() => handleProductClick(product)}
                      className="text-blue-500 hover:underline"
                    >
                      Edit Product
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ):

      (
        // <EditProduct
        // // selectedProductId={selectedProduct.id}
        //   product={selectedProduct}
        //   categories={categories}
        //   onBack={handleBackToProducts} // Pass the onBack callback
        // />
        <AddProduct
        product={selectedProduct}
        selectedCompany={selectedCompany}
        isEdit={true}
        toggleComponent={toggleComponent}
        />
      )}
    </div> : <div className="p-4 w-[100%] flex justify-center items-center h-[90vh] bg-[#f9f9f9] mx-auto font-sans text-[#848484]"> {Loading?<div>Loading...</div>:<div>Products Not found in Database</div>}</div>
  );
};

export default AllProducts;
