import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { getCroppedImg } from "./getCroppedImg"; // Utility function for cropping
import axios from "axios";
import { toast } from "react-toastify";

const ImageUploader = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  const handleCropSubmit = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    // croppedImage is the final image blob, which can be uploaded or displayed
    console.log(croppedImage);
    const formData = new FormData();
    formData.append('image', croppedImage);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      console.log(response.data.url)
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    }
    // For example, you can set the cropped image preview:
    // setCroppedImageUrl(URL.createObjectURL(croppedImage));
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="image-uploader h-[100vh]">
      {!imageSrc ? (
        <input type="file" accept="image/*" onChange={handleFileChange} />
      ) : (
        <div className="h-[100vh] ">
          <div style={{ position: "relative", width: "90%", height: 500 } } className="mt-4 ">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16/9} // Change aspect ratio as needed
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <Button variant="contained" onClick={handleCropSubmit}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
