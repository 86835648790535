import React from "react";
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import Header from "./Home_Web/Header";
// import Header from "./WebSite/Header";
// import HeroSection from "./WebSite/HeroSection";
const LandingPage = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  
  const openLoginPopup = () => {
    const width = window.innerWidth; // Window width
    const height = window.innerHeight;  // Fullscreen height
    const loginPopup = window.open(
      "/login", // The route where your Login component is rendered
      "LoginPopup", // The name of the popup window
      `width=${width},height=${height},menubar=no,toolbar=no,location=no,status=no,resizable=no,scrollbars=no`
    );

    if (!loginPopup || loginPopup.closed || typeof loginPopup.closed === "undefined") {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };


  return (
    // <div className="flex flex-col min-h-screen bg-gradient-to-r from-white to-gray-100">
    <div className="flex flex-col ">
      {/* <header className="px-6 lg:px-12 py-4 flex items-center justify-between shadow-lg bg-[#f5f7f7]"> */}
      {/* <Link className="flex items-center" to="/">
          <svg
            className="h-10 w-10 text-blue-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path d="M12 2l4 8H8l4-8z" />
            <path d="M12 12v10" />
            <path d="M9 21h6" />
          </svg>
          <span className="ml-3 text-xl font-bold text-blue-700">Company</span>
        </Link> */}
      {/* <nav className="flex gap-6">
          <Link
            className="text-lg text-gray-700 hover:text-blue-700"
            to="/"
          >
            Home
          </Link>
          <Link
            className="text-lg text-gray-700 hover:text-blue-700"
            to="/"
          >
            Product
          </Link>
          <Link
            className="text-lg text-gray-700 hover:text-blue-700"
            to="/"
          >
            About Us
          </Link>
          <Link
            className="text-lg text-gray-700 hover:text-blue-700"
            to="/"
          >
            Contact
          </Link>
        </nav> */}

      {/* </header> */}

       <main className="p-10px">
        <button
         onClick={openLoginPopup}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="absolute top-8 right-12 w-10 h-10 flex flex-col justify-center items-center bg-transparent rounded-md transition-all duration-300 focus:outline-none"
          aria-label="Toggle menu"
        >
          {[0, 1, 2].map((index) => (
            <span
              key={index}
              className={`w-6 h-0.5 bg-pink-500 mb-1.5 transition-all duration-300 ${isHovered ? 'w-7' : ''
                }`}
            ></span>
          ))}
        </button>
        <img
          src="https://res.cloudinary.com/dpdpstzft/image/upload/v1728468909/jjjj_oovhrh.jpg"
          alt="coming-soon"
          className=" w-screen h-screen"
          loading="eager"
        />
      </main> 
      {/* <Header/>
      <HeroSection/> */}
    </div>
  );
};

export default LandingPage;





















