import React, { useEffect, useState } from 'react'



// const PRODUCTS_PER_PAGE = 12;

// export default function Component({ quotationData }) {
//   const [pages, setPages] = useState([])
//   const [showPrintPreview, setShowPrintPreview] = useState(false)
//   const [subTotal, setSubTotal] = useState(0)
//   const [igstTotal, setIgstTotal] = useState(0)
//   const [grandTotal, setGrandTotal] = useState(0)

//   useEffect(() => {
//     const totalPages = Math.ceil(quotationData.items.length / PRODUCTS_PER_PAGE)
//     setPages(Array.from({ length: totalPages }, (_, i) => i + 1))
//   }, [])
//   useEffect(() => {
//     let subTotal = 0
//     let igstTotal = 0
//     let grandTotal = 0
//     quotationData.items.forEach((product) => {
//       subTotal += parseFloat(product.total)
//       igstTotal += parseFloat(product.total) * parseFloat(product.gst) / 100
//     })
//     grandTotal = subTotal + igstTotal + parseFloat(quotationData.packing)
//     setSubTotal(subTotal)
//     setIgstTotal(igstTotal)
//     setGrandTotal(grandTotal)
//   })
//   function numberToWords(num) {
//     const a = [
//       '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve',
//       'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
//     ];
//     const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
//     const crore = Math.floor(num / 10000000);
//     const lakh = Math.floor((num % 10000000) / 100000);
//     const thousand = Math.floor((num % 100000) / 1000);
//     const hundred = Math.floor((num % 1000) / 100);
//     const remainder = num % 100;

//     const getWords = (n) => {
//       if (n < 20) return a[n];
//       const tens = Math.floor(n / 10);
//       const ones = n % 10;
//       return b[tens] + (ones ? ' ' + a[ones] : '');
//     };

//     let words = '';

//     if (crore > 0) words += getWords(crore) + ' crore ';
//     if (lakh > 0) words += getWords(lakh) + ' lakh ';
//     if (thousand > 0) words += getWords(thousand) + ' thousand ';
//     if (hundred > 0) words += getWords(hundred) + ' hundred ';
//     if (remainder > 0) words += (words ? 'and ' : '') + getWords(remainder) + ' ';

//     return words.trim() + ' rupees only';
//   }
//   const amountInWords = numberToWords(parseInt(grandTotal));

//   const formattedDate = new Date(quotationData.date).toLocaleString('en-GB', {
//     day: 'numeric',
//     month: 'long',
//     year: 'numeric'
//   });

//   const QuotationHeader = () => (
//     // <div className="flex justify-between items-start mb-4 p-4">
//     //   <div className="flex flex-col items-start">
//     //     <div className='w-[50%] h-12' >
//     //     <img
//     //       src={`${quotationData.company.logo}?height=80&width=120`}
//     //       alt="Company Logo"
//     //       // width={150}
//     //       className=""
//     //     />
//     //     </div>

//     //     <div className="text-sm">
//     //       <h2 className="font-bold text-sm"><p>{quotationData.company.companyName.toUpperCase()}</p>
//     //       </h2>
//     //       <p>{quotationData.company.address}</p>
//     //       <p>GSTIN: {quotationData.company.gstNo}</p>
//     //     </div>
//     //   </div>
//     //   <div className="text-right">
//     //     <h1 className="text-2xl font-bold mb-2">Quotation</h1>
//     //     <div className="text-sm">
//     //       <p><strong>Quotation No:</strong> {quotationData.id}</p>
//     //       <p><strong>Quotation Date:</strong> {formattedDate}</p>
//     //       <p><strong>Contact Person:</strong> {quotationData.client.contactPerson}</p>
//     //       <p><strong>Phone No:</strong> {quotationData.client.contactPhone}</p>
//     //     </div>
//     //   </div>
//     // </div>
//     <div className="flex justify-between items-start mb-4 p-6 ">
//       {/* Left section: Company logo and details */}
//       <div className="flex flex-col items-start">
//         <div className="w-[50%] h-12 mb-10">
//           <img
//             src={`${quotationData.company.logo}`}
//             alt="Company Logo"
//             className=""
//           />
//         </div>

//         <div className="text-sm">
//           <h2 className="font-bold text-sm mb-1">{quotationData.company.companyName.toUpperCase()}</h2>
//           <p>{quotationData.company.address}</p>
//           {/* <p>{quotationData.company.city}, {quotationData.company.state}, {quotationData.company.pincode}</p> */}
//           <p>India</p>
//           <p className="mt-1 font-semibold">GSTIN: {quotationData.company.gstNo}</p>
//         </div>
//       </div>

//       {/* Right section: Quotation details */}
//       <div className="text-left">
//         <h1 className="text-2xl font-semibold mb-3 text-gray-600">Quotation</h1>
//         <div className="text-sm">
//           <p><strong>Quotation No:</strong> {quotationData.id}</p>
//           <p><strong>Quotation Date:</strong> {formattedDate}</p>
//           <p><strong>Contact Person:</strong> {quotationData.client.contactPerson}</p>
//           <p><strong>Phone No:</strong> {quotationData.client.contactPhone}</p>
//         </div>
//       </div>
//     </div>

//   )

//   const ClientDetails = () => (
//     <div className="flex justify-between mb-4 text-sm ">
//       <div className="w-1/2 ml-6 text-left"> {/* Align content to the left */}
//         <h3 className="mb-1 font-bold">Bill To</h3>
//         <p className='font-bold'>{quotationData.client.name}</p>
//         <p>{quotationData.client.address}</p>
//         <p className='font-bold'>GSTIN: {quotationData.client.gstNo}</p>
//       </div>
//       <div className="w-1/2 pl-2 ml-44 text-left"> {/* Align content to the right */}
//         <h3 className="font-bold mb-1">Ship To</h3>
//         <p>{quotationData.client.name}</p>
//         <p>{quotationData.client.address}</p>
//         <p className='font-bold'>GSTIN: {quotationData.client.gstNo}</p>
//       </div>
//     </div>
//   );

const PRODUCTS_PER_PAGE = 8;

export default function Component({ quotationData, type }) {
  const [totalProducts, setTotalProducts] = useState(quotationData.items.length)
  const [pages, setPages] = useState([])
  const [showPrintPreview, setShowPrintPreview] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [igstTotal, setIgstTotal] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  useEffect(() => {
    const totalPages = Math.ceil(quotationData.items.length / PRODUCTS_PER_PAGE)
    setPages(Array.from({ length: totalPages }, (_, i) => i + 1))
  }, [])
  useEffect(() => {
    let subTotal = 0
    let igstTotal = 0
    let grandTotal = 0
    quotationData.items.forEach((product) => {
      subTotal += parseFloat(product.total)
      igstTotal += parseFloat(product.total) * parseFloat(product.gst).toFixed(0) / 100
    })
    grandTotal = subTotal + igstTotal + parseFloat(quotationData.packing)
    setSubTotal(subTotal)
    setIgstTotal(igstTotal)
    setGrandTotal(grandTotal)
  })
  function numberToWords(num) {
    const a = [
      '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve',
      'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const crore = Math.floor(num / 10000000);
    const lakh = Math.floor((num % 10000000) / 100000);
    const thousand = Math.floor((num % 100000) / 1000);
    const hundred = Math.floor((num % 1000) / 100);
    const remainder = num % 100;

    const getWords = (n) => {
      if (n < 20) return a[n];
      const tens = Math.floor(n / 10);
      const ones = n % 10;
      return b[tens] + (ones ? ' ' + a[ones] : '');
    };

    let words = '';

    if (crore > 0) words += getWords(crore) + ' crore ';
    if (lakh > 0) words += getWords(lakh) + ' lakh ';
    if (thousand > 0) words += getWords(thousand) + ' thousand ';
    if (hundred > 0) words += getWords(hundred) + ' hundred ';
    if (remainder > 0) words += (words ? 'and ' : '') + getWords(remainder) + ' ';

    return words.trim() + ' rupees only';
  }
  const amountInWords = numberToWords(parseInt(grandTotal));

  const formattedDate = new Date(quotationData.date).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  const formatQuotationDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);

    // Custom logic for 3-character month or full name for 4 characters
    const [day, month, year] = formattedDate.split(" ");
    const customMonth =
      month.length === 4 ? new Date(date).toLocaleString("en-US", { month: "long" }) : month;

    return `${day} ${customMonth} ${year}`;
  };

  const QuotationHeader = ({ pageNumber }) => (
    <div className=" justify-between items-start  p-6 ">
      {/* Left section: Company logo and details */}
      <div className="flex justify-between  items-start">
        <div className="w-[30%] max-h-16 mb-6">
          <img
            src={`${quotationData.company.logo}`}
            alt="Company Logo"
            className="w-full max-h-16 object-contain"
          />
        </div>
        <div className={`${type === "PI" ? "mr-12" : "mr-[12rem]"} items-end`}>
          <h1 className="text-2xl font-semibold mb-3 text-gray-600">{type === 'quotation' ? "Quotation" : "Performa Invoice"}</h1>
        </div>
      </div>
      <div className='flex justify-between'>
        <div className="text-sm w-[67%]">
          <h2 className="font-bold text-lg mb-1">{quotationData.company.companyName.toUpperCase()}</h2>
          {quotationData.company.address.split("/0").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
          {/* <p>{quotationData.company.city}, {quotationData.company.state}, {quotationData.company.pincode}</p> */}
          <p>India</p>
          <p>{quotationData.company.city} {` , ${quotationData.company.pincode}`}</p>
          <p className="mt-1 font-semibold">GSTIN: {quotationData.company.gstNo}</p>
        </div>
        {/* Right section: Quotation details */}
        <div className="text-left mr-32 w-[32%]">
          <div className="text-sm text-left">
            <p><strong>{type === "PI" ? "PI No:" : "Quotation No:"}</strong> {quotationData.id}</p>
            <p><strong>{type === "PI" ? "PI Date: " : "Quotation Date:"}</strong> {formatQuotationDate(formattedDate)}</p>
            <p><strong>Contact Person:</strong> {quotationData.client.contactPerson}</p>
            <p><strong>Phone No:</strong> {quotationData.client.contactPhone}</p>
          </div>
        </div>
      </div>
    </div>

  )

  const ClientDetails = () => (
    <div className="flex justify-between text-sm w-[100%] ">
      <div className="w-[56%] ml-6 text-left"> {/* Align content to the left */}
        <h3 className="mb-1 font-bold">Bill To</h3>
        <p className='font-bold'>{quotationData.client.name}</p>
        <p>{quotationData.client.address.split('/0')[0]}</p>
        <p>{quotationData.client.address.split('/0')[1]}</p>
        <p>{quotationData.client.city} {` , ${quotationData.client.pincode}`}</p>
        <p className='font-bold'>GSTIN: {quotationData.client.gstNo}</p>
      </div>
      <div className=" w-[50%]    text-left"> {/* Align content to the right */}
        <h3 className="font-bold mb-1">Ship To</h3>
        <p className='font-bold'>{quotationData.client.name}</p>
        <p>{quotationData.client.address.split('/0')[0]}</p>
        <p>{quotationData.client.address.split('/0')[1]}</p>
        <p>{quotationData.client.city} {` , ${quotationData.client.pincode}`}</p>
        <p className='font-bold'>GSTIN: {quotationData.client.gstNo}</p>
      </div>
    </div>
  );

  const ProductTable = ({ isLastPage, pageNumber }) => {
    let rowsPerPage = PRODUCTS_PER_PAGE; // Define how many rows per page
    if (!isLastPage) {
      rowsPerPage = 10;
    }
    const startIndex = (pageNumber - 1) * 10;
    const endIndex = startIndex + rowsPerPage;
    const pageProducts = quotationData.items.slice(startIndex, endIndex > totalProducts ? (startIndex + totalProducts % 10) : endIndex);
    let serialNo = startIndex;

    const rowHeight = 30; // Approximate height for each product row (can be adjusted)
    const headerHeight = 100; // Height of the header (adjust if necessary)
    const pageHeight = 600; // Max page height (adjust to match the actual page size)

    // Function to calculate how many rows fit per page
    const calculateRowsPerPage = () => {
      const remainingHeight = pageHeight - headerHeight; // Space left after header
      const rowsFit = Math.floor(remainingHeight / rowHeight); // How many rows fit
      return rowsFit;
    };

    const rowsForCurrentPage = calculateRowsPerPage();
    const renderProductRows = () => {
      const renderedRows = [];
      let spaceLeft = pageHeight - headerHeight; // Available space for rows on this page

      pageProducts.forEach((product, index) => {
        const rowActualHeight = rowHeight; // Placeholder logic for row height; adjust if necessary

        if (spaceLeft > rowActualHeight) {
          renderedRows.push(
            <tr key={index} className='h-10'>
              <td className="border border-gray-800 p-1 text-center">{serialNo + index + 1}</td>
              <td className="border border-gray-800 p-1 text-left">{product.name || ""}</td>
              <td className="border border-gray-800 p-1 text-center">{product.hsn || ""}</td>
              <td className="border border-gray-800 p-1 text-center">{product.quantity || ""}</td>
              <td className="border border-gray-800 p-1 text-right">{product.price !== undefined && product.price !== null
                ? Number(product.price).toFixed(2)
                : ""}</td>
              <td className="border border-gray-800 p-1 text-center">{product.discount || ""}</td>
              <td className="border border-gray-800 p-1 text-right">{product.price ? Number(product.price).toFixed(2) : ""}</td> {/*todo*/}
              <td className="border border-gray-800 p-1 text-center pl-4">{parseFloat(product.gst) / 2 || ""}{product.gst / 2 ? "%" : ""}</td>
              <td className="border border-gray-800 p-1 text-right">{product.gst ? ((parseFloat(product.total) * (parseFloat(product.gst) / 200)).toFixed(2)) : ""}</td>
              <td className="border border-gray-800 p-1 text-center pl-4">{parseFloat(product.gst) / 2 || ""}{product.gst ? "%" : ""}</td>
              <td className="border border-gray-800 p-1 text-right">{product.gst ? ((parseFloat(product.total) * (parseFloat(product.gst) / 200)).toFixed(2)) : ""}</td>
              <td className="border border-gray-800 p-1 text-right">{product.total ? parseFloat(product.total).toFixed(2) : ""}</td>
            </tr>

          );
          spaceLeft -= rowActualHeight; // Decrease available space after each row
        }
      });

      return renderedRows;
    };

    return (
      <div className="p-1">
        <table className="w-full border-collapse border border-gray-800  text-xs mt-4">
          {(pageProducts.length > 0) && <thead>
            <tr >
              <th className="border border-gray-800 p-1 text-center w-[7%]">S. No</th>
              <th className="border border-gray-800 p-1 text-center">Description</th>
              <th className="border border-gray-800 p-1 text-center w-[8%]">HSN</th>
              <th className="border border-gray-800 p-1 text-center w-[6%]">Qty</th>
              <th className="border border-gray-800 p-1 text-center w-[8%]">Rates/Unit <p>(INR)</p></th>
              <th className="border border-gray-800 p-1 text-center w-[6%]">Disc %</th>
              <th className="border border-gray-800 p-1 text-center w-[9%]">Net Rate <p>(INR)</p></th>
              <th className="border border-gray-800  text-center w-[15%]" colSpan="2">
                <p className='w-[103%] border border-gray-800 ml-[-0.10rem] mt-[-0.10rem]'>CGST</p>
                <tr className='w-[100%] '>
                  <th className="border-r border-gray-800 p-1 text-center w-[59%] ">%</th>
                  <th className=" p-1 text-center w-[50%]">Amt</th>
                </tr>
              </th>
              <th className="border border-gray-800 text-center w-[15%]" colSpan="2">
                <p className='w-[103%] border border-gray-800 ml-[-0.10rem] mt-[-0.10rem]'>SGST</p>
                <tr className='w-[100%] '>
                  <th className="border-r border-gray-800 p-1 text-center w-[59%] ">%</th>
                  <th className=" p-1 text-center w-[50%]">Amt</th>
                </tr>
              </th>
              <th className="border border-gray-800 p-1 text-center w-[9%]">Amount (INR)</th>
            </tr>

          </thead>}
          <tbody>
            {renderProductRows()}
            {isLastPage && (
              <>
                <tr>
                  <td colSpan="11" className="border border-gray-800 p-1 text-right font-semibold">Sub Total</td>
                  <td className="border border-gray-800 p-1 text-right">{subTotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="11" className="border border-gray-800 p-1 text-right font-semibold">Packaging & Handling</td>
                  <td className="border border-gray-800 p-1 text-right">{quotationData.packing.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="11" className="border border-gray-800 p-1 text-right font-semibold">IGST</td>
                  <td className="border border-gray-800 p-1 text-right">{igstTotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="11" className="border border-gray-800 p-1 text-right font-semibold">GRAND TOTAL INR</td>
                  <td className="border border-gray-800 p-1 text-right">{grandTotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="12" className="border border-gray-800 p-1 text-left"><strong>Amount in Words :</strong> {amountInWords.toUpperCase()}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };



  const QuotationFooter = ({ isLastPage, pageNumber }) => (
    <div className={(totalProducts <= 8 && totalProducts > 6) || (totalProducts > 6 && quotationData.terms > 1) ? ' text-xs' : " text-xs"}>
      {isLastPage && (
        <>
          <div className={(totalProducts <= 8 && totalProducts > 6) || (totalProducts > 6 && quotationData.terms > 1) ? "flex justify-between mb-4 mt-[-0.5rem] " : "flex justify-between mb-4 mt-[-0.5rem]"}>
          </div>
          <div className="flex justify-between mb-4">
            <div className="w-1/2 pr-2">
              <h3 className="font-bold mb-1">Terms & Conditions:</h3>
              <ol className="list-decimal list-inside">
                {quotationData.terms.map((term, index) => (
                  <li key={index}>{term}</li>
                ))}
              </ol>
            </div>
            <div className="w-1/2 pl-2 flex flex-col items-end justify-end">
              {
                type === "PI" && <div className='w-44 text-left items-start flex mb-4'>
                  <div className='w-1/2'>
                    <p className='font-semibold'>Bank Details:</p>
                    <p><span className='inline-block'>Name </span></p>
                    <p>Bank</p>
                    <p>A/C No</p>
                    <p>IFSC Code</p>
                  </div>
                  <div className='w-1/2'>
                    <p className=' invisible text-white font-extralight'>. </p>
                    <p>: {quotationData?.company?.accountName}</p>
                    <p>: {quotationData?.company?.bankName}</p>
                    <p>: {quotationData?.company?.bankAccount}</p>
                    <p>: {quotationData?.company?.bankIFSC}</p>
                  </div>
                </div>
              }
              <div>
                <p className="font-bold mb-1">For {quotationData.company.companyName}</p>
                <div className="mt-4 border-t border-gray-300 pt-1 w-32 text-center">
                  <p>Authorized Signatory</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={`footer  w-full h-16 bg-gray-200 flex flex-col items-center justify-center `}>
        <img
          src={`${quotationData.company.banner}?height=64&width=680`}
          alt="Company Banner"
          className="object-fit-cover w-[710px] h-[70px]"
          style={{ objectFit: "cover" }}
        />

      </div>
      <style jsx>{`
      @media print {
        /* Banner should appear at the bottom of every page */
        .footer {
          position: fixed;
          bottom: 2%;
          left: 0;
          width: 100%;
          height: 70px;
          background: white;
        }

        /* Content of the last page */
        .last-page-content {
          margin-bottom: 80px; /* Ensure there's space above the footer */
        }

        /* Ensure footer doesn't overlap */
        body {
          padding-bottom: 70px; /* Match footer height to prevent overlap */
        }
      }
    `}</style>
    </div>
  )


  const handlePrint = () => {
    const printWindow = window.open('', 'PRINT', 'height=600,width=800');
    console.log("Inside Print in a4 quatation");
    if (printWindow) {
      // Prepare your print content (React-generated HTML)
      const printContent = document.getElementById('printableArea'); // Assuming you're wrapping your content in an element with id="printableArea"

      printWindow.document.write('<html><head><title>Print Preview</title>');
      // Link external CSS or inject inline styles for printing
      printWindow.document.write(`
          <style>
            body {
              font-family: sans-serif;
            }
            /* Add your custom print styles here */
            @media print {
              @page {
                size: A4;
                margin: 20mm; /* Set margins for A4 size */
              }
              .page-break {
                page-break-after: always;
              }
            }
            /* Ensure content fits correctly */
            .quotation-container {
              width: 210mm; /* A4 page width */
              margin: 0 auto; /* Center content */
            }
            .company-banner {
              width: 100%;
              height: auto;
            }
          </style>
        `);
      printWindow.document.write('</head><body>');
      printWindow.document.write(printContent.innerHTML); // Insert the content to print
      printWindow.document.write('</body></html>');

      // Finalize and print
      printWindow.document.close(); // Close document to ensure it's ready for print
      printWindow.focus(); // Ensure the print window is in focus
      printWindow.print(); // Trigger the print

      printWindow.onafterprint = () => {
        printWindow.close(); // Close the print window after printing
      };

      setShowPrintPreview(false); // Optionally hide print preview
    } else {
      alert('Unable to open print window. Please check your popup settings.');
    }
  };




  return (
    <div className='h-[97%] overflow-hidden '>

      <div className="w-[210mm] mx-auto bg-white p-[10mm] text-black">
        {pages.map((pageNumber) => (
          <div key={pageNumber} className=" page-break-after-always ">
            <QuotationHeader pageNumber={pageNumber} />
            <ClientDetails />
            <ProductTable pageNumber={pageNumber} className="mt-4" isLastPage={pageNumber === pages.length} />
            <QuotationFooter isLastPage={pageNumber === pages.length} pageNumber={pageNumber} />
            <div className="text-right text-xs mt-2 ">Page {pageNumber} of {pages.length}</div>
          </div>
        ))}
      </div>
      <style jsx global>{`
        @media print {
          @page {
            size: A4;
            margin: 0;
          }
          body {
            margin: 0;
          }
          .page-break-after-always {
            page-break-after: always;
          }
          body * {
            visibility: hidden;
          }
          .print-preview, .print-preview * {
            visibility: visible;
          }
          .print-preview {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `}</style>
    </div>
  )
}