// // import React, { useEffect, useState } from 'react';

// // function HomePage() {

// //     const [data, setData] = useState(null);
// //     const [editMode, setEditMode] = useState(false);

// //     // Fetch data on mount
// //     useEffect(() => {
// //         fetch('http://localhost:3000/api/v1/home/get')
// //             .then((response) => response.json())
// //             .then((fetchedData) => setData(fetchedData))
// //             .catch((error) => console.error('Error fetching data:', error));
// //     }, []);

// //     // Handle save action
// //     const handleSave = () => {
// //         fetch('http://localhost:3000/api/v1/home/update', {
// //             method: 'POST',
// //             headers: {
// //                 'Content-Type': 'application/json',
// //             },
// //             body: JSON.stringify(data),
// //         })
// //             .then((response) => response.json())
// //             .then((updatedData) => {
// //                 setData(updatedData);
// //                 setEditMode(false);
// //                 alert('Data saved successfully!');
// //             })
// //             .catch((error) => console.error('Error saving data:', error));
// //     };

// //     // Handle input changes
// //     const handleInputChange = (section, key, value) => {
// //         setData((prevData) => ({
// //             ...prevData,
// //             [section]: {
// //                 ...prevData[section],
// //                 [key]: value,
// //             },
// //         }));
// //     };

// //     if (!data) return <div>Loading...</div>;

// //     return (
// //         <div className=''>
// //             {/* <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-green-500">
// //                 <h1 className="text-2xl font-semibold text-gray-800 mb-4">Home</h1>
// //                 <p>The Images and exact same content from website is being displayed here. Change here accordingly.</p>
// //             </div> */}
// //             <div className="container p-4">
// //                 {/* Carousel Section */}
// //                 <div className="mb-8">
// //                     <h2 className="text-2xl font-bold mb-4">Carousel</h2>
// //                     {Object.entries(data.caurosel).map(([key, carousel]) => (
// //                         <div key={key} className="mb-4">
// //                             <h3 className="font-semibold mb-2">{carousel.heading}</h3>
// //                             {editMode ? (
// //                                 <div>
// //                                     <input
// //                                         className="border p-2 mb-2 w-full"
// //                                         value={carousel.about}
// //                                         onChange={(e) => handleInputChange('caurosel', key, {
// //                                             ...carousel,
// //                                             about: e.target.value,
// //                                         })}
// //                                     />
// //                                     <input
// //                                         className="border p-2 mb-2 w-full"
// //                                         value={carousel.more}
// //                                         onChange={(e) => handleInputChange('caurosel', key, {
// //                                             ...carousel,
// //                                             more: e.target.value,
// //                                         })}
// //                                     />
// //                                     <input
// //                                         className="border p-2 mb-2 w-full"
// //                                         value={carousel.heading}
// //                                         onChange={(e) => handleInputChange('caurosel', key, {
// //                                             ...carousel,
// //                                             heading: e.target.value,
// //                                         })}
// //                                     />
// //                                     <input
// //                                         className="border p-2 mb-2 w-full"
// //                                         value={carousel.imageUrl}
// //                                         onChange={(e) => handleInputChange('caurosel', key, {
// //                                             ...carousel,
// //                                             imageUrl: e.target.value,
// //                                         })}
// //                                     />
// //                                 </div>
// //                             ) : (
// //                                 <div>
// //                                     <p><strong>About:</strong> {carousel.about}</p>
// //                                     <p><strong>More:</strong> {carousel.more}</p>
// //                                     <p><strong>Image:</strong> <img src={carousel.imageUrl} alt="Carousel" /></p>
// //                                 </div>
// //                             )}
// //                         </div>
// //                     ))}
// //                 </div>



// //                 {/* Edit Mode Toggle */}
// //                 <button
// //                     className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
// //                     onClick={() => setEditMode(!editMode)}
// //                 >
// //                     {editMode ? 'Cancel' : 'Edit'}
// //                 </button>
// //                 {editMode && (
// //                     <button
// //                         className="px-4 py-2 bg-green-500 text-white rounded"
// //                         onClick={handleSave}
// //                     >
// //                         Save
// //                     </button>
// //                 )}
// //             </div>

// //         </div>
// //     );
// // }

// // export default HomePage;



// import React, { useEffect, useState } from 'react';

// function HomePage() {
//     const [data, setData] = useState(null);
//     const [editMode, setEditMode] = useState(false);

//     // Fetch data on mount
//     useEffect(() => {
//         fetch('http://localhost:3000/api/v1/home/get')
//             .then((response) => response.json())
//             .then((fetchedData) => setData(fetchedData))
//             .catch((error) => console.error('Error fetching data:', error));
//     }, []);

//     // Handle save action
//     const handleSave = () => {
//         console.log(data);
//         const token = localStorage.getItem('token')
//         fetch('http://localhost:3000/api/v1/home/update', {
//             method: 'PATCH',
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data),
//         })
//             .then((response) => response.json())
//             .then((updatedData) => {
//                 setData(updatedData);
//                 setEditMode(false);
//                 alert('Data saved successfully!');
//             })
//             .catch((error) => console.error('Error saving data:', error));
//     };

//     // Handle input changes
//     const handleInputChange = (section, key, value) => {
//         setData((prevData) => ({
//             ...prevData,
//             [section]: {
//                 ...prevData[section],
//                 [key]: value,
//             },
//         }));
//     };

//     // Handle image upload
//     const handleImageUpload = (section, key, file) => {
//         const reader = new FileReader();
//         reader.onload = (e) => {
//             setData((prevData) => ({
//                 ...prevData,
//                 [section]: {
//                     ...prevData[section],
//                     [key]: {
//                         ...prevData[section][key],
//                         imageUrl: e.target.result, // Preview the uploaded image
//                     },
//                 },
//             }));
//         };
//         reader.readAsDataURL(file);
//     };

//     if (!data) return <div>Loading...</div>;

//     return (
//         <div className="container mx-auto p-4">
//             <h1 className="text-2xl font-bold mb-4">Edit Home Page</h1>

//             {/* Carousel Section */}
//             <div className="mb-8">
//                 <h2 className="text-xl font-bold mb-4">Carousel</h2>
//                 {Object.entries(data.caurosel).map(([key, carousel]) => (
//                     <div key={key} className="mb-4">
//                         <h3 className="font-semibold mb-2">Carousel: {carousel.heading}</h3>
//                         {editMode ? (
//                             <div className="space-y-4">
//                                 <div>
//                                     <label className="block font-semibold">About:</label>
//                                     <input
//                                         className="border p-2 w-full"
//                                         value={carousel.about}
//                                         onChange={(e) =>
//                                             handleInputChange('caurosel', key, {
//                                                 ...carousel,
//                                                 about: e.target.value,
//                                             })
//                                         }
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block font-semibold">More:</label>
//                                     <textarea
//                                         className="border p-2 w-full"
//                                         value={carousel.more}
//                                         onChange={(e) =>
//                                             handleInputChange('caurosel', key, {
//                                                 ...carousel,
//                                                 more: e.target.value,
//                                             })
//                                         }
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block font-semibold">Heading:</label>
//                                     <input
//                                         className="border p-2 w-full"
//                                         value={carousel.heading}
//                                         onChange={(e) =>
//                                             handleInputChange('caurosel', key, {
//                                                 ...carousel,
//                                                 heading: e.target.value,
//                                             })
//                                         }
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block font-semibold">Image:</label>
//                                     <img
//                                         src={carousel.imageUrl}
//                                         alt="Carousel"
//                                         className="w-32 h-32 object-cover mb-2"
//                                     />
//                                     <input
//                                         type="file"
//                                         accept="image/*"
//                                         onChange={(e) =>
//                                             handleImageUpload('caurosel', key, e.target.files[0])
//                                         }
//                                     />
//                                 </div>
//                             </div>
//                         ) : (
//                             <div>
//                                 <p><strong>About:</strong> {carousel.about}</p>
//                                 <p><strong>More:</strong> {carousel.more}</p>
//                                 <p><strong>Heading:</strong> {carousel.heading}</p>
//                                 <p>
//                                     <strong>Image:</strong>
//                                     <img
//                                         src={carousel.imageUrl}
//                                         alt="Carousel"
//                                         className="w-32 h-32 object-cover"
//                                     />
//                                 </p>
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>

//             {/* Edit Mode Toggle */}
//             <div className="flex gap-4">
//                 <button
//                     className="px-4 py-2 bg-blue-500 text-white rounded"
//                     onClick={() => setEditMode(!editMode)}
//                 >
//                     {editMode ? 'Cancel' : 'Edit'}
//                 </button>
//                 {editMode && (
//                     <button
//                         className="px-4 py-2 bg-green-500 text-white rounded"
//                         onClick={handleSave}
//                     >
//                         Save
//                     </button>
//                 )}
//             </div>
//         </div>
//     );
// }

// export default HomePage;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function HomePage() {
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [content, setContent] = useState("");

    const [iframeLoaded, setIframeLoaded] = useState(false); // Track iframe load status

    useEffect(() => {
        if (iframeLoaded) {
            const iframe = document.getElementById("specific-element-iframe");

            if (iframe) {
                console.log(iframe.contentDocument)
                // iframe.onload = () => {
                try {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                    console.log(iframeDoc);

                    if (iframeDoc) {
                        const targetElement = iframeDoc.querySelector(".header-carousel");
                        console.log(targetElement)
                        if (targetElement) {
                            // Clear everything in iframe and append only the target element
                            iframeDoc.body.innerHTML = "";
                            iframeDoc.body.appendChild(targetElement.cloneNode(true));
                        } else {
                            console.error("Element with ID 'carousel-sec' not found!");
                        }
                    } else {
                        console.error("Unable to access iframe document.");
                    }
                } catch (error) {
                    console.error("Error accessing iframe content:", error);
                }
            };
        } else {
            // console.error("Iframe not found!");
        }
    }
        , [iframeLoaded]);

    // Fetch data on mount
    useEffect(() => {
        fetch('https://emkayindia.in/api/v1/home/get')
            .then((response) => response.json())
            .then((fetchedData) => setData(fetchedData))
            .catch((error) => console.error('Error fetching data:', error));

        // axios
        //     .get("https://cors-anywhere.herokuapp.com/https://example.com") // Proxy URL
        //     .then((response) => {
        //         const parser = new DOMParser();
        //         const doc = parser.parseFromString(response.data, "text/html");
        //         const specificContent = doc.querySelector("#specific-section");
        //         setContent(specificContent?.outerHTML || "No content found");
        //     })
        //     .catch((error) => console.error("Error fetching content via proxy:", error));

        // const iframe = document.getElementById("specific-element-iframe");

        // iframe ? iframe.onload = () => {
        //     try {
        //         const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        //         // Locate the element with ID `carousel-sec`
        //         const targetElement = iframeDoc.querySelector("#carousel-sec");
        //         if (targetElement) {
        //             // Clear the entire iframe content
        //             iframeDoc.body.innerHTML = "";

        //             // Append the `carousel-sec` element back
        //             iframeDoc.body.appendChild(targetElement);
        //         } else {
        //             console.error("Element with ID 'carousel-sec' not found!");
        //         }
        //     } catch (error) {
        //         console.error("Error accessing iframe content:", error);
        //     }
        // } :
        //     console.error("Iframe not found!");
        // ;
    }, []);

    // useEffect(() => {
    //     const iframe = document.getElementById("specific-element-iframe");

    //     if (iframe) {
    //       iframe.onload = () => {
    //         try {
    //           const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //           if (iframeDoc) {
    //             const targetElement = iframeDoc.querySelector("#carousel-sec");
    //             if (targetElement) {
    //               iframeDoc.body.innerHTML = "";
    //               iframeDoc.body.appendChild(targetElement.cloneNode(true));
    //             } else {
    //               console.error("Element with ID 'carousel-sec' not found!");
    //             }
    //           } else {
    //             console.error("Unable to access iframe document.");
    //           }
    //         } catch (error) {
    //           console.error("Error accessing iframe content:", error);
    //         }
    //       };
    //     } else {
    //       console.error("Iframe not found!");
    //     }
    //   }, []);

    // Image upload function
    const uploadImage = async (file) => {
        setIsUploading(true); // Start loading state
        const formData = new FormData();
        formData.append('image', file);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.url; // Return the uploaded image URL
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Error uploading image');
            return null;
        } finally {
            setIsUploading(false); // Stop loading state
        }
    };

    // Handle save action
    const handleSave = () => {
        const token = localStorage.getItem('token');
        console.log(data);
        fetch('https://emkayindia.in/api/v1/home/update', {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((updatedData) => {
                setData(updatedData);
                setEditMode(false);
                toast.success('Data saved successfully!');
            })
            .catch((error) => console.error('Error saving data:', error));
    };

    // Handle input changes
    const handleInputChange = (section, key, value) => {
        setData((prevData) => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [key]: value,
            },
        }));
    };

    // Handle image upload and update data
    const handleImageUpload = async (section, key, file) => {
        // const imageUrl = await uploadImage(file);
        setIsUploading(true); // Start loading state
        const formData = new FormData();
        formData.append('image', file);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });;
            const imageUrl = response.data.url
            if (imageUrl) {
                setData((prevData) => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [key]: {
                            ...prevData[section][key],
                            imageUrl, // Set the uploaded image URL
                        },
                    },
                }));
            }
            return response.data.url; // Return the uploaded image URL
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Error uploading image');
            return null;
        } finally {
            setIsUploading(false); // Stop loading state
        }



    };

    if (!data) return <div>Loading...</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Edit Home Page</h1>
            <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
                {/* <iframe
                    src="./WebSite/index.html" // Path to your existing project (HTML file in public folder)
                    title="Embedded Website"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        overflow: 'hidden',
                    }}
                /> */}
                <iframe
                    id="specific-element-iframe"
                    src="https://quatation-v.netlify.app/"
                    title="Embedded Website"
                    onLoad={() => setIframeLoaded(true)} // Set iframeLoaded to true when iframe is loaded
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        // overflow: "hidden",
                    }}
                />
            </div>

            {/* Carousel Section */}
            <div className="mb-8">
                <h2 className="text-xl font-bold mb-4">Carousel</h2>
                {Object.entries(data.caurosel).map(([key, carousel]) => (
                    <div key={key} className="mb-4">
                        <h3 className="font-semibold mb-2">Carousel: {carousel.heading}</h3>
                        {editMode ? (
                            <div className="space-y-4">
                                <div>
                                    <label className="block font-semibold">About:</label>
                                    <input
                                        className="border p-2 w-full"
                                        value={carousel.about}
                                        onChange={(e) =>
                                            handleInputChange('caurosel', key, {
                                                ...carousel,
                                                about: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block font-semibold">More:</label>
                                    <textarea
                                        className="border p-2 w-full"
                                        value={carousel.more}
                                        onChange={(e) =>
                                            handleInputChange('caurosel', key, {
                                                ...carousel,
                                                more: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block font-semibold">Heading:</label>
                                    <input
                                        className="border p-2 w-full"
                                        value={carousel.heading}
                                        onChange={(e) =>
                                            handleInputChange('caurosel', key, {
                                                ...carousel,
                                                heading: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block font-semibold">Image:</label>
                                    <img
                                        src={carousel.imageUrl}
                                        alt="Carousel"
                                        className="w-32 h-32 object-cover mb-2"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                            handleImageUpload('caurosel', key, e.target.files[0])
                                        }
                                        disabled={isUploading}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p><strong>About:</strong> {carousel.about}</p>
                                <p><strong>More:</strong> {carousel.more}</p>
                                <p><strong>Heading:</strong> {carousel.heading}</p>
                                <p>
                                    <strong>Image:</strong>
                                    <img
                                        src={carousel.imageUrl}
                                        alt="Carousel"
                                        className="w-32 h-32 object-cover"
                                    />
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Edit Mode Toggle */}
            <div className="flex gap-4">
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={() => setEditMode(!editMode)}
                >
                    {editMode ? 'Cancel' : 'Edit'}
                </button>
                {editMode && (
                    <button
                        className="px-4 py-2 bg-green-500 text-white rounded"
                        onClick={handleSave}
                        disabled={isUploading}
                    >
                        {isUploading ? 'Uploading...' : 'Save'}
                    </button>
                )}
            </div>
        </div>
    );
}

export default HomePage;
