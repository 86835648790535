// import React, { useState } from 'react';

// function WebSite() {
//     const [visibleSection, setVisibleSection] = useState('home');

//     return (
//         <div className="min-h-screen bg-gray-100 mt-2">
//             {/* Navbar */}
//             <div className="bg-gray-800 text-white py-4 shadow-md">
//                 <div className="container mx-auto flex justify-center space-x-4">
//                     <button
//                         onClick={() => setVisibleSection('home')}
//                         className={`px-4 py-2 rounded-md transition ${
//                             visibleSection === 'home' ? 'bg-gray-700' : 'hover:bg-gray-600'
//                         }`}
//                     >
//                         Home
//                     </button>
//                     <button
//                         onClick={() => setVisibleSection('about')}
//                         className={`px-4 py-2 rounded-md transition ${
//                             visibleSection === 'about' ? 'bg-gray-700' : 'hover:bg-gray-600'
//                         }`}
//                     >
//                         About
//                     </button>
//                     <button
//                         onClick={() => setVisibleSection('product')}
//                         className={`px-4 py-2 rounded-md transition ${
//                             visibleSection === 'product' ? 'bg-gray-700' : 'hover:bg-gray-600'
//                         }`}
//                     >
//                         Product
//                     </button>
//                     <button
//                         onClick={() => setVisibleSection('contact')}
//                         className={`px-4 py-2 rounded-md transition ${
//                             visibleSection === 'contact' ? 'bg-gray-700' : 'hover:bg-gray-600'
//                         }`}
//                     >
//                         Contact
//                     </button>
//                 </div>
//             </div>

//             {/* Content Section */}
//             <div className="container mx-auto mt-8 px-4 overflow-y-auto">
//                 {visibleSection === 'home' && (
//                     <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-green-500">
//                         <h1 className="text-2xl font-semibold text-gray-800 mb-4">Home</h1>
//                         <p>The Images and exact same content from website is being displayed here change here accordingly </p>
//                     </div>
//                 )}
//                 {visibleSection === 'about' && (
//                     <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-purple-500">
//                         <h1 className="text-2xl font-semibold text-gray-800 mb-4">About</h1>
//                         <p>Learn more about us and our journey to where we are today.</p>
//                     </div>
//                 )}
//                 {visibleSection === 'product' && (
//                     <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-yellow-500">
//                         <h1 className="text-2xl font-semibold text-gray-800 mb-4">Product</h1>
//                         <p>Explore our range of products designed to make your life easier.</p>
//                     </div>
//                 )}
//                 {visibleSection === 'contact' && (
//                     <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-blue-500">
//                         <h1 className="text-2xl font-semibold text-gray-800 mb-4">Contact</h1>
//                         <p>We'd love to hear from you! Get in touch with us for any queries.</p>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }

// export default WebSite;



import React, { useState } from 'react';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProductPage from './components/ProductPage';
import ContactPage from './components/ContactPage';

function WebSite() {
    const [visibleSection, setVisibleSection] = useState('home');

    return (
        <div className="h-[100vh] bg-gray-100  overflow-auto ">
            {/* Navbar */}
            <div className="bg-gray-800 text-white py-4 shadow-md ">
                <div className="container mx-auto flex justify-center space-x-4">
                    <button
                        onClick={() => setVisibleSection('home')}
                        className={`px-4 py-2 rounded-md transition ${
                            visibleSection === 'home' ? 'bg-gray-700' : 'hover:bg-gray-600'
                        }`}
                    >
                        Home
                    </button>
                    <button
                        onClick={() => setVisibleSection('about')}
                        className={`px-4 py-2 rounded-md transition ${
                            visibleSection === 'about' ? 'bg-gray-700' : 'hover:bg-gray-600'
                        }`}
                    >
                        About
                    </button>
                    <button
                        onClick={() => setVisibleSection('product')}
                        className={`px-4 py-2 rounded-md transition ${
                            visibleSection === 'product' ? 'bg-gray-700' : 'hover:bg-gray-600'
                        }`}
                    >
                        Product
                    </button>
                    <button
                        onClick={() => setVisibleSection('contact')}
                        className={`px-4 py-2 rounded-md transition ${
                            visibleSection === 'contact' ? 'bg-gray-700' : 'hover:bg-gray-600'
                        }`}
                    >
                        Contact
                    </button>
                </div>
            </div>

            {/* Content Section */}
            <div className="container mx-auto mt-4 mb-16 px-4 ">
                {visibleSection === 'home' && <HomePage />}
                {visibleSection === 'about' && <AboutPage />}
                {visibleSection === 'product' && <ProductPage />}
                {visibleSection === 'contact' && <ContactPage />}
            </div>
        </div>
    );
}

export default WebSite;
