import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProductDetails from './ProductDetails';

const AllCategories = () => {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [isSubcategoryModalOpen, setIsSubcategoryModalOpen] = useState(false);
    const [newSubcategoryName, setNewSubcategoryName] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCategoryToDisplaySubCategory, setSelectedCategoryToDisplaySubCategory] = useState(null);
    const [subCategories, setSubCategories] = useState(null);
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('https://emkayindia.in/api/v1/category/all', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCategories(response.data);
            setFilteredCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories', error);
        }
    };

    const fetchProductsByCategory = async (categoryId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`https://emkayindia.in/api/v1/category/allproducts?categoryId=${categoryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setProducts(response.data.Product);
            console.log(response.data.Product);
            setFilteredProducts(response.data.Product);
        } catch (error) {
            console.error('Error fetching products', error);
        }
    };

    const fetchSubCategoriesByCategory = async (categoryId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`https://emkayindia.in/api/v1/subcategory/all?categoryId=${categoryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSubCategories(response.data);
            // console.log(response.data.Product);
            // setFilteredProducts(response.data.Product);
        } catch (error) {
            console.error('Error fetching products', error);
        }
    }

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        fetchProductsByCategory(category.id);
    };

    const handleSubCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedCategoryToDisplaySubCategory(category);
        fetchSubCategoriesByCategory(category.id);
    }

    const handleBackToCategories = () => {
        setSelectedCategory(null);
        setProducts([]);
        setFilteredProducts([]);
        setSelectedCategoryToDisplaySubCategory(null);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredCategories(categories.filter(category =>
            category.name.toLowerCase().includes(query)
        ));
    };

    const handleProductSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setProductSearchQuery(query);
        setFilteredProducts(products.filter(product =>
            product.name.toLowerCase().includes(query)
        ));
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewCategoryName('');
    };

    const handleSaveCategory = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post('https://emkayindia.in/api/v1/category/create', { name: newCategoryName }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            closeModal();
            fetchCategories();
            toast.success("Category Added");
        } catch (error) {
            toast.error("Error Occured While Adding Category");
            console.error('Error adding category', error);
        }
    };

    const openSubcategoryModal = () => {
        setIsSubcategoryModalOpen(true);
    };

    const closeSubcategoryModal = () => {
        setIsSubcategoryModalOpen(false);
        setNewSubcategoryName('');
    };

    const handleSaveSubcategory = async () => {
        const token = localStorage.getItem('token');
        console.log("Subcategory submit Button clicked")
        console.log(newSubcategoryName);
        console.log(selectedCategory.id)
        try {
            await axios.post('https://emkayindia.in/api/v1/subcategory/create', {
                name: newSubcategoryName,
                category: selectedCategory.id,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            closeSubcategoryModal();
            toast.success("Subcategory Added");
        } catch (error) {
            toast.error("Error Occured While Adding Subcategory");
            console.error('Error adding subcategory', error);
        }
    };

    const fetchProductDetails = async (productId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`https://emkayindia.in/api/v1/product/get/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response?.data); // Confirm structure in console
            if (Array.isArray(response.data) && response.data.length > 0) {
                setSelectedProduct(response.data[0]); // Access the first item in the array
            } else {
                console.error("Product data is not in the expected format");
            }
        } catch (error) {
            console.error('Error fetching product details', error);
        }
    };

    const handleBackToProducts = () => {
        setSelectedProduct(null);
    };
    const handleProductClick = (product) => {
        fetchProductDetails(product.id);
    };


    return (
        <div className="all-categories-container p-4 w-[100%] mx-auto mt-10 text-[#848484] overflow-y-scroll h-[99%]">
            {!selectedCategory ? (
                <div className="ml-2">
                    <div className='flex justify-between'>
                        <h2 className="text-2xl font-bold mb-4">All Categories</h2>
                        <button
                            onClick={openModal}
                            className="bg-[#C6C5B9]  text-[#848484] font-semibold py-2 px-4 rounded-md shadow-lg"
                        >
                            Add Category
                        </button>
                    </div>

                    <div className="mb-4 flex items-center justify-between w-[70%]">
                        <strong>Search <p>Category:</p></strong>
                        <input
                            type="text"
                            placeholder="Search categories name..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-[85%] px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Category Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Created At</th>
                                    {/* <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Products Under Category</th> */}
                                    <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">SubCategories Category</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-100 divide-y divide-gray-200">
                                {filteredCategories.map((category) => (
                                    <tr
                                        key={category.id}
                                        className="hover:bg-gray-100 cursor-pointer transition duration-200"

                                    >
                                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium border-2">{category.id}</td>
                                        <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{category.name}</td>
                                        <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{new Date(category.createdAt).toString().split("G")[0]}</td>
                                        {/* <td className="px-6 py-2 whitespace-nowrap text-sm border-2 text-blue-500 underline" onClick={() => handleCategoryClick(category)}>All Products</td> */}
                                        <td className="px-6 py-2 whitespace-nowrap text-sm border-2 text-blue-500 underline" onClick={() => handleSubCategoryClick(category)}>All SubCategories</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 text-[#848484]">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                                <h2 className="text-xl  font-bold mb-4">Add Category</h2>
                                <input
                                    type="text"
                                    placeholder="Category Name"
                                    value={newCategoryName}
                                    onChange={(e) => setNewCategoryName(e.target.value)}
                                    className="w-full px-4 py-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={closeModal}
                                        className=" font-semibold py-2 px-4 rounded-md mr-2 border-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveCategory}
                                        className="bg-[#C6C5B9]  text-[#848484] font-semibold py-2 px-4 rounded-md"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (!selectedProduct &&
                <div className="category-details pl-4">
                    <div className="flex justify-end">
                        <button
                            onClick={openSubcategoryModal}
                            className="bg-[#C6C5B9]  text-[#848484] font-semibold py-2 px-4 rounded-md shadow-lg mr-6"
                        >
                            Add Subcategory
                        </button>
                        <button
                            onClick={handleBackToCategories}
                            className=" bg-[#C6C5B9]  text-[#848484] font-semibold py-2 px-4 rounded-md shadow-lg"
                        >
                            Back to Categories
                        </button>

                    </div>
                    {
                        selectedCategoryToDisplaySubCategory && (<div className="overflow-x-auto">
                            <div className='flex justify-between'>
                                <h2 className="text-2xl font-bold mb-4">All SubCategories under {selectedCategory.name} Category</h2>

                            </div>
                            {subCategories && subCategories.length>1 ? <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                                <thead className="bg-gray-200">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">ID</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">SubCategory Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Created At</th>
                                        {/* <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Products Under Category</th> */}
                                        {/* <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">SubCategories Category</th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-100 divide-y divide-gray-200">
                                    {subCategories && subCategories.map((category) => (
                                        <tr
                                            key={category.id}
                                            className="hover:bg-gray-100 cursor-pointer transition duration-200"

                                        >
                                            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium border-2">{category.id}</td>
                                            <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{category.name}</td>
                                            <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{new Date(category.createdAt).toString().split("G")[0]}</td>
                                            {/* <td className="px-6 py-2 whitespace-nowrap text-sm border-2 text-blue-500 underline" onClick={() => handleCategoryClick(category)}>All Products</td> */}
                                            {/* <td className="px-6 py-2 whitespace-nowrap text-sm border-2 text-blue-500 underline" onClick={() => handleSubCategoryClick(category)}>All SubCategories</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>:<div className="text-center ">No SubCategories Found for {selectedCategory.name}</div>}
                        </div>
                        )

                        // (products.length > 1 ?
                        //     <div>
                        //         <h2 className="text-2xl font-bold mb-4">{selectedCategory.name} - Products</h2>
                        //         <div className="mb-4 flex items-center w-[50%]  justify-between">
                        //             <strong>Search <p>Products:</p></strong>
                        //             <input
                        //                 type="text"
                        //                 placeholder="Search product name..."
                        //                 value={productSearchQuery}
                        //                 onChange={handleProductSearchChange}
                        //                 className="w-[80%] px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
                        //             />
                        //         </div>

                        //         <div className="overflow-x-auto">
                        //             <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                        //                 <thead className="bg-gray-200">
                        //                     <tr>
                        //                         <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">ID</th>
                        //                         <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Product Name</th>
                        //                         <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider w-[15%]">Sub Category</th>
                        //                         <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Created At</th>
                        //                     </tr>
                        //                 </thead>
                        //                 <tbody className="bg-white divide-y divide-gray-200">
                        //                     {filteredProducts.map((product) => (
                        //                         //  <tr key={product.id} onClick={() => handleProductClick(product)} className="hover:bg-gray-100 cursor-pointer">
                        //                         <tr key={product.id} className="hover:bg-gray-100 cursor-pointer">
                        //                             <td className="px-6 py-2 whitespace-nowrap text-sm font-medium border-2">{product.id}</td>
                        //                             <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{product.name}</td>
                        //                             <td className="px-6 py-2 whitespace-nowrap text-sm border-2 text-center">{product.subCategoryId}</td>
                        //                             <td className="px-6 py-2 whitespace-nowrap text-sm border-2">{new Date(product.createdAt).toString().split("G")[0]}</td>
                        //                         </tr>
                        //                     ))}
                        //                 </tbody>
                        //             </table>
                        //         </div>
                        //     </div> : <div className="text-center ">No Products Found for {selectedCategory.name}</div>)
                    }

                    {isSubcategoryModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                                <h2 className="text-xl font-bold mb-4">Add Subcategory</h2>
                                <input
                                    type="text"
                                    placeholder="Subcategory Name"
                                    value={newSubcategoryName}
                                    onChange={(e) => setNewSubcategoryName(e.target.value)}
                                    className="w-full px-4 py-2 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={closeSubcategoryModal}
                                        className="border-2  text-[#848484] font-semibold py-2 px-4 rounded-lg mr-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveSubcategory}
                                        className="bg-[#C6C5B9]  text-[#848484] font-semibold py-2 px-4 rounded-md"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
            {selectedProduct && (
                <ProductDetails product={selectedProduct} onBack={handleBackToProducts} />
            )
            }
        </div>
    );
};

export default AllCategories;
