import axios from "axios";
import React, { useState, useEffect } from "react";

const ProductsComponent = () => {
    const [productsData, setProductsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [initialData, setInitialData] = useState(null);

    const apiEndpoint = "https://emkayindia.in/api/v1/home/get";
    const uploadEndpoint = "https://emkayindia.in/api/v1/image/upload";
    const patchEndpoint = "http://localhost:3000/api/v1/home/update";
    const token = localStorage.getItem("token");

    // Fetch Products Data
    useEffect(() => {
        const fetchProductsData = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                setProductsData(data.privacy.products);
                setInitialData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductsData();
    }, [apiEndpoint, token]);

    // Enter Edit Mode
    const handleEdit = (product) => {
        setEditingProduct({ ...product }); // Create a copy of the product
        setEditMode(true);
    };

    // Image Upload Handler
    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        setImageUploading(true);
        try {
            const response = await axios.post(uploadEndpoint, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response)
            setEditingProduct((prev) => ({
                ...prev,
                image: response.data.url,
            }));

            alert("Image uploaded successfully!");
        } catch (err) {
            console.error("Image upload error:", err);
            alert("Failed to upload image: " + (err.response?.data?.message || err.message));
        } finally {
            setImageUploading(false);
        }
    };

    // Save Updated Product
    // const handleSave = async () => {
    //     try {
    //         const response = await fetch(patchEndpoint, {
    //             method: "PATCH",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify(editingProduct),
    //         });

    //         if (!response.ok) {
    //             throw new Error("Failed to update product");
    //         }

    //         alert("Product updated successfully!");
    //         setEditMode(false);
    //         setEditingProduct(null);

    //         // Refetch the updated product list
    //         const updatedResponse = await fetch(apiEndpoint, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         const updatedData = await updatedResponse.json();
    //         setProductsData(updatedData.privacy.products);
    //     } catch (err) {
    //         alert("Error saving product: " + err.message);
    //     }
    // };

    const handleSave = async () => {
        console.log("editing product"+editingProduct);
        console.log("Product data"+productsData);
        console.log("initial data"+initialData);
        try {
            // Map over the original productsData to retain the structure
            const updatedProducts = productsData.map((category) => {
                return {
                    ...category,
                    items: category.items.map((item) =>
                        item.id === editingProduct.id ? editingProduct : item
                    ),
                };
            });
            console.log(updatedProducts);
            initialData.privacy.products = updatedProducts;
    
            // Send the updated data in the request body
            const response = await fetch(patchEndpoint, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(initialData), // Wrap the updated data in the expected structure
            });
    
            if (!response.ok) {
                throw new Error("Failed to update product");
            }
    
            alert("Product updated successfully!");
            setEditMode(false);
            setEditingProduct(null);
    
            // Update the local state with the updated products data
            setProductsData(updatedProducts);
        } catch (err) {
            alert("Error saving product: " + err.message);
        }
    };
    
    // Cancel Edit
    const handleCancel = () => {
        setEditMode(false);
        setEditingProduct(null);
    };

    if (loading) {
        return <div className="p-6">Loading...</div>;
    }

    if (error) {
        return <div className="p-6 text-red-500">Error: {error}</div>;
    }

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">Products</h2>
            {!editMode ? (
                productsData?.map((category) => (
                    <div key={category.id} className="mb-6">
                        <h3 className="text-xl font-semibold mb-3">{category.category}</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {category.items.map((item, index) => (
                                <div
                                    key={index}
                                    className="border p-4 rounded shadow-md bg-white"
                                >
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        className="h-40 w-full object-cover rounded mb-3"
                                    />
                                    <h4 className="text-lg font-semibold">{item.title}</h4>
                                    <p className="text-gray-600 mb-3">{item.description}</p>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleEdit(item)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <div className="border p-6 rounded shadow-md bg-white">
                    <h3 className="text-xl font-semibold mb-4">Edit Product</h3>
                    <div className="mb-4">
                        <label className="block font-medium mb-1">Title</label>
                        <input
                            type="text"
                            className="w-full border px-3 py-2 rounded"
                            value={editingProduct.title}
                            onChange={(e) =>
                                setEditingProduct({ ...editingProduct, title: e.target.value })
                            }
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium mb-1">Description</label>
                        <textarea
                            className="w-full border px-3 py-2 rounded"
                            rows="4"
                            value={editingProduct.description}
                            onChange={(e) =>
                                setEditingProduct({
                                    ...editingProduct,
                                    description: e.target.value,
                                })
                            }
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium mb-1">Image</label>
                        <img
                            src={editingProduct.image}
                            alt="Product"
                            className="h-40 w-full object-cover rounded mb-3"
                        />
                        <input
                            type="file"
                            onChange={(e) => handleImageUpload(e.target.files[0])}
                            className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        {imageUploading && <p className="text-gray-500 mt-2">Uploading...</p>}
                    </div>
                    <div className="flex space-x-4">
                        <button
                            className="btn btn-primary"
                            onClick={handleSave}
                            disabled={imageUploading}
                        >
                            Save
                        </button>
                        <button className="btn btn-secondary" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductsComponent;
