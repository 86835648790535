// import React from 'react';

// function AboutPage() {
//     return (
//         <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-purple-500">
//             <h1 className="text-2xl font-semibold text-gray-800 mb-4">About</h1>
//             <p>Learn more about us and our journey to where we are today.</p>
//         </div>
//     );
// }

// export default AboutPage;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const AboutComponent = () => {
//   const [aboutData, setAboutData] = useState({});
//   const [isEditing, setIsEditing] = useState(false);
//   const [formData, setFormData] = useState({
//     title: "",
//     subtitle: "",
//     description: "",
//     features: [],
//     services: [],
//   });

//   // Fetch the token from localStorage
//   const token = localStorage.getItem("token");

//   // Fetch data on component mount
//   useEffect(() => {
//     axios
//       .get("http://localhost:3000/api/v1/home/get", {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((response) => {
//         setAboutData(response.data.about);
//         setFormData(response.data.about);
//       })
//       .catch((error) => console.error("Error fetching about data:", error));
//   }, [token]);

//   const handleEditToggle = () => {
//     setIsEditing(!isEditing);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSave = () => {
//     const about={about: formData};
//     console.log(about)
//     axios
//       .patch(
//         "http://localhost:3000/api/v1/home/update",
//         about,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((response) => {
//         setAboutData(response.data);
//         setIsEditing(false);
//       })
//       .catch((error) => console.error("Error updating about data:", error));
//   };

// return (
//     <div className="p-6 bg-gray-100">
//         {isEditing ? (
//             <form className="space-y-4">
//                 <input
//                     type="text"
//                     name="title"
//                     value={formData.title}
//                     onChange={handleInputChange}
//                     className="block w-full p-2 border rounded"
//                 />
//                 <input
//                     type="text"
//                     name="subtitle"
//                     value={formData.subtitle}
//                     onChange={handleInputChange}
//                     className="block w-full p-2 border rounded"
//                 />
//                 <textarea
//                     name="description"
//                     value={formData.description}
//                     onChange={handleInputChange}
//                     className="block w-full p-2 border rounded"
//                 />
//                 {formData.features.map((feature, index) => (
//                     <input
//                         key={index}
//                         type="text"
//                         name={`feature-${index}`}
//                         value={feature}
//                         onChange={(e) => {
//                             const newFeatures = [...formData.features];
//                             newFeatures[index] = e.target.value;
//                             setFormData({ ...formData, features: newFeatures });
//                         }}
//                         className="block w-full p-2 border rounded"
//                     />
//                 ))}
//                 {/* Additional fields for services */}
//                 <button
//                     type="button"
//                     onClick={handleSave}
//                     className="px-4 py-2 bg-blue-500 text-white rounded"
//                 >
//                     Save
//                 </button>
//             </form>
//         ) : (
//             <div>
//                 <h1 className="text-2xl font-bold">{aboutData.title}</h1>
//                 <h2 className="text-xl text-gray-600">{aboutData.subtitle}</h2>
//                 <p className="my-4">{aboutData.description}</p>
//                 <ul>
//                     {aboutData.features?.map((feature, index) => (
//                         <li key={index}>• {feature}</li>
//                     ))}
//                 </ul>
//                 <div className="grid grid-cols-2 gap-4">
//                     {aboutData.services?.map((service, index) => (
//                         <div key={index} className="flex items-center space-x-2">
//                             <span>{service.icon}</span>
//                             <span>{service.title}</span>
//                         </div>
//                     ))}
//                 </div>
//                 <button
//                     onClick={handleEditToggle}
//                     className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
//                 >
//                     Edit
//                 </button>
//             </div>
//         )}
//     </div>
// );
// };

// export default AboutComponent;




import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AboutComponent = () => {
    const [aboutData, setAboutData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        subtitle: "",
        description: "",
        features: [],
        services: [],
    });

    // Fetch the token from localStorage
    const token = localStorage.getItem("token");

    const [iframeLoaded, setIframeLoaded] = useState(false); // Track iframe load status

    useEffect(() => {
        if (iframeLoaded) {
            const iframe = document.getElementById("specific-element-iframe");

            if (iframe) {
                console.log(iframe.contentDocument)
                // iframe.onload = () => {
                try {
                    const iframeDoc = iframe.contentDocument;


                    if (iframeDoc) {
                        const targetElement = iframeDoc.querySelector(".AboutSection");
                        console.log(targetElement)
                        if (targetElement) {
                            // Clear everything in iframe and append only the target element
                            iframeDoc.body.innerHTML = "";
                            iframeDoc.body.appendChild(targetElement.cloneNode(true));
                        } else {
                            console.error("Element with ID 'about-sec' not found!");
                        }
                    } else {
                        console.error("Unable to access iframe document.");
                    }
                } catch (error) {
                    console.error("Error accessing iframe content:", error);
                }
            };
        } else {
            console.error("Iframe not found!");
        }
    }
        , [iframeLoaded]);

    // Fetch data on component mount
    useEffect(() => {
        axios
            .get("https://emkayindia.in/api/v1/home/get", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setAboutData(response.data.about);
                setFormData(response.data.about);
            })
            .catch((error) => console.error("Error fetching about data:", error));
    }, [token]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const about = { about: formData };

        axios
            .patch("https://emkayindia.in/api/v1/home/update", about, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setAboutData(response.data.about);
                setIsEditing(false);
                toast.success("About data updated successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((error) => console.error("Error updating about data:", error));
    };

    return (
        <div className="p-6 bg-gray-100 h-min-screen">
            <h2 className="text-2xl font-bold text-gray-700">Edit About Page</h2>
            <div style={{ width: '100%', height: '100vh', position: 'relative' }} className="mt-4">
                {/* <iframe
                    src="./WebSite/index.html" // Path to your existing project (HTML file in public folder)
                    title="Embedded Website"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        overflow: 'hidden',
                    }}
                /> */}
                <iframe
                    id="specific-element-iframe"
                    src="./WebSite/index.html"
                    title="Embedded Website"
                    onLoad={() => setIframeLoaded(true)} // Set iframeLoaded to true when iframe is loaded
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        overflow: "hidden",
                    }}
                />
            </div>
            <ToastContainer />
            {isEditing ? (
                <div className=" space-y-4 transition-transform transform scale-100">

                    {/* Title */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Title</h3>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            placeholder="Title"
                            className="block w-full p-2 border border-gray-300 rounded"
                            readOnly
                        />
                    </div>
                    {/* Subtitle */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Subtitle</h3>
                        <input
                            type="text"
                            name="subtitle"
                            value={formData.subtitle}
                            onChange={handleInputChange}
                            placeholder="Subtitle"
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    {/* Description */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Description</h3>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder="Description"
                            className="block w-full p-2 border border-gray-300 rounded h-24"
                        />
                    </div>
                    {/* Features */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Features</h3>
                        {formData.features.map((feature, index) => (
                            <div key={index} className="mb-2">
                                <input
                                    type="text"
                                    name={`feature-${index}`}
                                    value={feature}
                                    onChange={(e) => {
                                        const newFeatures = [...formData.features];
                                        newFeatures[index] = e.target.value;
                                        setFormData({ ...formData, features: newFeatures });
                                    }}
                                    placeholder={`Feature ${index + 1}`}
                                    className="block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                        ))}
                    </div>
                    {/* Services */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Services</h3>
                        {formData.services.map((service, index) => (
                            <div key={index} className="flex items-center mb-2 space-x-2">
                                <input
                                    type="text"
                                    name={`service-icon-${index}`}
                                    value={service.icon}
                                    placeholder="Icon"
                                    onChange={(e) => {
                                        const newServices = [...formData.services];
                                        newServices[index].icon = e.target.value;
                                        setFormData({ ...formData, services: newServices });
                                    }}
                                    className="p-2 border border-gray-300 rounded w-12"
                                />
                                <input
                                    type="text"
                                    name={`service-title-${index}`}
                                    value={service.title}
                                    placeholder="Service Title"
                                    onChange={(e) => {
                                        const newServices = [...formData.services];
                                        newServices[index].title = e.target.value;
                                        setFormData({ ...formData, services: newServices });
                                    }}
                                    className="flex-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                        ))}
                    </div>
                    {/* Action Buttons */}
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={handleEditToggle}
                            className="px-4 py-2 bg-gray-500 text-white rounded"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Save
                        </button>
                    </div>
                </div>
            ) : (
                <div className=" p-6 transition-transform transform scale-100">
                    {/* <h1 className="text-3xl text-gray-800 font-bold">About Us Page</h1>
                    <h1 className="text-xl  text-gray-600 mt-2">Heading: {aboutData.title}</h1>

                    <h2 className="text-xl text-gray-600 mt-2">Subtitle: {aboutData.subtitle}</h2>

                    <div className="mt-6 grid grid-cols-2 gap-4">
                        {aboutData.services?.map((service, index) => (
                            <div
                                key={index}
                                className="p-4 bg-gray-50  flex items-center space-x-2"
                            >
                                <span className="text-2xl">{service.icon}</span>
                                <span className="text-gray-700">{service.title}</span>
                            </div>
                        ))}
                    </div>
                    <p className="mt-4 text-gray-700">Description: {aboutData.description}</p>
                    <p className="mt-4 text-gray-700">Features:</p>
                    <ul className="mt-4 space-y-2">
                        {aboutData.features?.map((feature, index) => (
                            <li key={index} className="text-gray-600">
                                • {feature}
                            </li>
                        ))}
                    </ul> */}

                    <button
                        onClick={handleEditToggle}
                        className="mt-6 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};

export default AboutComponent;
