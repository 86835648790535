// import React from 'react';

// function ContactPage() {
//     return (
//         <div className="p-8 bg-white rounded-md shadow-md border-l-4 border-blue-500">
//             <h1 className="text-2xl font-semibold text-gray-800 mb-4">Contact</h1>
//             <p>We'd love to hear from you! Get in touch with us for any queries.</p>
//         </div>
//     );
// }

// export default ContactPage;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactComponent = () => {
    const [contactData, setContactData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        heading: "",
        contactDescription: "",
        address: "",
        email: "",
        phone: "",
    });

    // Fetch the token from localStorage
    const token = localStorage.getItem("token");

    const [iframeLoaded, setIframeLoaded] = useState(false); // Track iframe load status
    
        useEffect(() => {
            if (iframeLoaded) {
                const iframe = document.getElementById("specific-element-iframe");
    
                if (iframe) {
                    console.log(iframe.contentDocument)
                    // iframe.onload = () => {
                    try {
                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    
    
                        if (iframeDoc) {
                            const targetElement = iframeDoc.querySelector(".ContactSection");
                            console.log(targetElement)
                            if (targetElement) {
                                // Clear everything in iframe and append only the target element
                                iframeDoc.body.innerHTML = "";
                                iframeDoc.body.appendChild(targetElement.cloneNode(true));
                            } else {
                                console.error("Element with ID 'carousel-sec' not found!");
                            }
                        } else {
                            console.error("Unable to access iframe document.");
                        }
                    } catch (error) {
                        console.error("Error accessing iframe content:", error);
                    }
                };
            } else {
                console.error("Iframe not found!");
            }
        }
            , [iframeLoaded]);

    // Fetch data on component mount
    useEffect(() => {
        axios
            .get("https://emkayindia.in/api/v1/home/get", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setContactData(response.data.contact);
                setFormData(response.data.contact);
            })
            .catch((error) => console.error("Error fetching contact data:", error));
    }, [token]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const contact = { contact: formData };

        axios
            .patch("https://emkayindia.in/api/v1/home/update", contact, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setContactData(response.data.contact);
                setIsEditing(false);
                toast.success("Contact data updated successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((error) => console.error("Error updating contact data:", error));
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
             {/* <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
                <iframe
                    id="specific-element-iframe"
                    src="./WebSite/contact.html"
                    title="Embedded Website"
                    onLoad={() => setIframeLoaded(true)} // Set iframeLoaded to true when iframe is loaded
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        overflow: "hidden",
                    }}
                />
            </div> */}
            <ToastContainer />
            {isEditing ? (
                <div className="space-y-6 transition-transform transform scale-100">
                    <h2 className="text-3xl font-bold text-gray-700">Edit Contact</h2>
                    {/* Heading */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Heading</h3>
                        <input
                            type="text"
                            name="heading"
                            value={formData.heading}
                            onChange={handleInputChange}
                            placeholder="Heading"
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    {/* Contact Description */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Contact Description</h3>
                        <textarea
                            name="contactDescription"
                            value={formData.contactDescription}
                            onChange={handleInputChange}
                            placeholder="Contact Description"
                            className="block w-full p-2 border border-gray-300 rounded h-24"
                        />
                    </div>
                    {/* Address */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Address</h3>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder="Address"
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    {/* Email */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Email</h3>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Email"
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    {/* Phone */}
                    <div>
                        <h3 className="text-lg font-medium text-gray-800">Phone</h3>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Phone"
                            className="block w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    {/* Action Buttons */}
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={handleEditToggle}
                            className="px-4 py-2 bg-gray-500 text-white rounded"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Save
                        </button>
                    </div>
                </div>
            ) : (
                <div className=" transition-transform transform scale-100 space-y-3">
                    <h2 className="text-3xl font-bold   text-gray-700">Edit Contact</h2>
                    <h1 className="text-xl mt-2 text-gray-600"><strong>Contact Heading:</strong> {contactData.heading}</h1>
                    <p className="mt-2 text-gray-600"><strong>Description:</strong> {contactData.contactDescription}</p>
                    <p className="mt-4 text-gray-700">
                        <strong>Address:</strong> {contactData.address}
                    </p>
                    <p className="mt-2 text-gray-700">
                        <strong>Email:</strong> {contactData.email}
                    </p>
                    <p className="mt-2 text-gray-700">
                        <strong>Phone:</strong> {contactData.phone}
                    </p>
                    <button
                        onClick={handleEditToggle}
                        className="mt-6 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};

export default ContactComponent;
